export enum RolesEnum {
  //Roles Enum
  Block = 0,
  Obliterator = 1,
  Security = 2,
  Engineer = 3,
  Management = 4,
  Commons_support = 5,
  Secc_it = 6,
  Secc_events = 7,
}

export const RoleLabels = [
  'No Role',
  'Obliterator',
  'Security',
  'Engineer',
  'Management',
  'Commons Support',
  'Secc It',
  'Secc Events',
];

export enum PathNames {
  readers = 'readers',
  cameras = 'cameras',
  intercoms = 'intercoms',
  downstreams = 'downstreams',
  instance_logs = 'instance_logs',
  controllers = 'controllers',
  user_log = 'user_log',
  hardware_users = 'hardware_users',
  instances_list = 'instances_list',
  elevators = 'elevators',
  error = 'error',
  analytic = 'analytic',
  instance_groups = 'instance_groups',
  instance_groups_action = 'instance_groups_action',
  sys_log = 'sys_log',
  counting_areas = 'counting_areas',
  holiday = 'holiday',
  country_reports = 'country_reports',
}

export enum LoadDataErrors {
  loginError = 'loginError',
  loadDataError = 'loadDataError',
  unknownError = 'unknownError',
  notError = 'notError',
}

export enum analyticTypeEnum {
  semi = 'semi',
  full = 'full',
}

export type navigationItem = {
  content: string;
  path: PathNames;
  isInstanceRequired: boolean;
};

export type navigationGroupType = {
  label: string;
  icon: string;
  items: navigationItem[];
};

export type paginationType = {
  page: number;
  limit: number;
};

export type userForAnalyticType = {
  user_email: string;
  user_role: number[];
};

export type AnalyticDataType = {
  current: {
    visits: number;
    unique_visits: number;
  };
  previous: {
    visits: number;
    unique_visits: number;
  };
};
export type AnalyticCardDataType = {
  name: string;
  chartData: chartDataType;
  infoForVisitsChart: InfoForChartType;
  type: analyticTypeEnum;
};

export type CameraAnalyticsType = {
  name: string;
  chartData: chartDataType;
  type: analyticTypeEnum;
};

export type ActionAnalyticDataType = {
  user_action: string;
  action_count: number;
};

export type AnalyticActionResponseListType = {
  actions: ActionAnalyticDataType[];
};

export type chartDataType = {
  labels?: string[];
  options?: any;
  datasets: {
    data: number[];
    backgroundColor?: string[];
    cutout?: string;
    display: boolean;
    border: string;
    borderColor: string;
    circumference?: number;
    rotation?: number;
  }[];
};

export type InfoForChartType = {
  visitNumber?: number;
  percentageDiff?: number | string;
  data: number[];
  backgroundColor?: string[];
};

export enum DurationEnum {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export type AnalyticParams = {
  role?: number;
  duration?: DurationEnum;
  ip_address: string;
  instance?: string;
  server?: number;
  platform?: string;
};

export type BackendVersionType = {
  version: string
}