import React, { useEffect, useRef, useState } from 'react';
import s from './Header.module.css';
import Profile from '../../modules/Profile/Profile';
import { useSelector } from 'react-redux';
import { AppRootStateType } from '../../../store/store';
import { useIsNarrower } from '../../../utils/useIsNarrower';
import { Button } from '@wework/ray2';
import { useIsNeedHeaderSpoiler } from '../../../utils/useIsNeedHeaderSpoiler';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FeedbackBlock from '../../modules/Feedback/Feedback';

type propsType = {
  header?: any;
  subHeader?: any;
  additionalText?: string;
};

const Header: React.FC<propsType> = (props) => {
  const { header, subHeader, additionalText } = props;

  //const currentInstanceName = useSelector<AppRootStateType, string>(state => state.app.currentInstanceName)
  const instance_name = useSelector<AppRootStateType, string | undefined>(
    (state) => state.app.currentInstanceData?.instance_name,
  );

  const headerMainBlock = useRef<any>(null);
  const headerContentBlock = useRef<any>(null);

  const [isNeedSpoiler] = useIsNeedHeaderSpoiler(headerContentBlock, headerMainBlock);

  const isMobile = useIsNarrower(920);

  const [isOpenSpoiler, setIsOpenSpoiler] = useState<boolean>(false);

  useEffect(() => {
    setIsOpenSpoiler(false);
  }, [isNeedSpoiler]);

  return (
    <div className={`${s.headerWrapper} ${isMobile && s.headerWrapper_mobile}`}>
      <div className={s.headerBody}>
        <div className={s.headerContent} ref={headerContentBlock}>
          <div className={s.headerContentHeader}>
            {header}

            {isNeedSpoiler && subHeader && (
              <Button
                size="medium"
                theme={isOpenSpoiler ? 'fill' : 'outline'}
                onClick={() => setIsOpenSpoiler(!isOpenSpoiler)}
              >
                {!isMobile && (isOpenSpoiler ? 'Less' : 'More')}
                <span className="icon-button">
                  <MoreHorizIcon fontSize={isMobile ? 'medium' : 'small'} />
                </span>
              </Button>
            )}
            {additionalText && <div className={s.additionalText}>{additionalText}</div>}
          </div>

          <div
            className={`${s.headerContentHeader} ${
              isNeedSpoiler && s.headerContentHeader__spoiler
            }`}
          >
            {subHeader}
          </div>
        </div>

        <div className={s.headerMain} ref={headerMainBlock}>
          {!isMobile && (
            <div className={`${s.headerInstance} text-primary`}>
              {instance_name && `Instance: ${instance_name}`}
            </div>
          )}
          <FeedbackBlock />
          <Profile />
        </div>
      </div>
      {isNeedSpoiler && (
        <div
          className={`${s.headerContentSubHeader} ${
            isOpenSpoiler && s.headerContentSubHeader_open
          }`}
        >
          {subHeader}
        </div>
      )}
    </div>
  );
};

export default Header;
