import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import { InstanceLogsFilterTableType } from '../../../types/instanceLogTypes';
import { useDispatch } from 'react-redux';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
import { setMessageAC, setPersonUUIDAC, setReaderNameAC } from '../../../store/instanceLogsReducer';

const initFilters = {
  'message': '',
  'reader_name': '',
  'person': '',
} as InstanceLogsFilterTableType;

const InstanceFilterTable = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<InstanceLogsFilterTableType>(initFilters);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, parameter: string) => {
    setFilters({ ...filters, [parameter]: event.target.value });
  };
  
  const debouncedPerson = useDebouncedValue(filters.person, 500);
  const debouncedReader = useDebouncedValue(filters.reader_name, 500);
  const debouncedMessage = useDebouncedValue(filters.message, 500);

  useEffect(() => {
    dispatch(setPersonUUIDAC(debouncedPerson));
  }, [debouncedPerson, dispatch]);

  useEffect(() => {
    dispatch(setReaderNameAC(debouncedReader));
  }, [debouncedReader, dispatch]);

  useEffect(() => {
    dispatch(setMessageAC(debouncedMessage));
  }, [debouncedMessage, dispatch]);

  return <FormControl style={{  display: 'flex', gap: '0.5em' }}>
    <div style={{  display: 'flex', gap: '1em', justifyContent: 'space-between' }}>
      <TextField
        style={{ width: '100%' }}
        value={filters['reader_name']}
        type={'text'}
        size={'small'}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'reader_name')}
        label="Enter reader"
      />
      <TextField
        style={{ width: '100%' }}
        value={filters['person']}
        type={'text'}
        size={'small'}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'person')}
        label="Enter person"
      />
    </div>
    <TextField
      value={filters['message']}
      type={'text'}
      size={'small'}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'message')}
      label="Enter message"
    />
  </FormControl>;
}; 

export default InstanceFilterTable;