import { useDispatch, useSelector } from 'react-redux';
import { AppRootStateType } from '../../../store/store'; 
import { weworkAPI } from '../../../api/api';
import { InstanceLogsFilter, InstanceLogsFilterType } from '../../../types/instanceLogTypes';
import { setFilterTypeAC,
  setInstanceLogsAC, setLogsIsLoading } from '../../../store/instanceLogsReducer';
import { addNotificationAC } from '../../../store/appReducer';
import { notificationEnum } from '../../../types/appTypes';
import { useCurrentInstanceData } from '../../../store/selectors/useCurrentInstanceData';
import { useCurrentIp } from '../../../store/selectors/useCurrentIp';
import React, { useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { utils_instance } from '../../../utils/Utils';


const InstanceFilter = () => {
  const filterType = useSelector(
    (state: AppRootStateType) =>  state.instanceLogs.selectedFilterType);
  const dispatch = useDispatch();
  const currentInstanceData = useCurrentInstanceData();
  const currentIp = useCurrentIp();

  const { pageSize, dateFrom, dateTo,
    pagination, isLoading, personUUID, readerName, message } = useSelector(
    (state: AppRootStateType) => state.instanceLogs);

  const applyFilter = async () => {
    if (!currentInstanceData) {
      return null;
    }

    if (filterType === 'Live') {
      return;
    }

    dispatch(setLogsIsLoading(true));
    try {
      const utcStartingOn = dateFrom ?
        utils_instance.convertLocalToUTCWithTime(dateFrom) : undefined;
      const utcEndingOn = dateTo ? utils_instance.convertLocalToUTCWithTime(dateTo) : undefined;
      const logs = await weworkAPI.getInstanceLogs(
        currentIp,
        currentInstanceData.instance_id,
        currentInstanceData.server_id,
        currentInstanceData.platform,
        {
          pageSize: pagination.limit,
          page: pagination.page - 1,
          ...(filterType === 'Date' && {
            startingOn: utcStartingOn,
            endingOn: utcEndingOn,
          }),
          ...(personUUID && { personUUID }),
          ...(readerName && { readerName }),
          ...(message && { message }),
        } as InstanceLogsFilter
      );
      dispatch(setInstanceLogsAC(logs));
      dispatch(addNotificationAC(notificationEnum.success, 'load logs success'));
    } catch (e: any) {
      dispatch(setInstanceLogsAC([]));

      dispatch(
        addNotificationAC(notificationEnum.error, `load logs failed - ${e.response?.data?.detail}`),
      );
    }
  };

  useEffect(() => { 
    applyFilter();
  }, [filterType,
    dateFrom,
    dateTo,
    pageSize,
    pagination,
    currentInstanceData, personUUID, readerName, message]);
  
  const filters = ['Live', 'Latest', 'Date'] as InstanceLogsFilterType[];
  const changeLogsType = (e: SelectChangeEvent<InstanceLogsFilterType>) => {
    const selectedFilter = e.target.value as InstanceLogsFilterType;

    if (filters.includes(selectedFilter)) {
      dispatch(setFilterTypeAC(selectedFilter));
    }

    if (selectedFilter === 'Live') {
      dispatch(setInstanceLogsAC([]));
    }
  };

  return <div style={{  display: 'flex', gap: '1em' }}>
    <FormControl fullWidth size={'small'}
      disabled={isLoading}
      key={'logs_selector'}>
      <InputLabel id={'logs_selector_label'}>Filter by</InputLabel>
      <Select
        size="small"
        labelId={'logs_selector_type'}
        label='Filter by'
        id={'logs_selector_id'}
        fullWidth
        value={filterType}
        onChange={(e) => changeLogsType(e)}
        IconComponent={() => null}
      >
        {filters.map((filter) => {
          return (
            <MenuItem key={`filter_${filter}`} value={filter}>
              {filter}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>

  </div>;
}; 

export default InstanceFilter;