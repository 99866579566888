import { useDispatch, useSelector } from 'react-redux';
import { AppRootStateType } from '../../../store/store';
import {
  setCountryDateFromAC,
  setCountryReportDateToAC,
} from '../../../store/countryReportsReducer';
import FilterDatePicker from '../../templates/FilterDatePicker/FilterDatePicker';


const CountryFilterDatePicker = () => {
  const dispatch = useDispatch();
  const { dateFrom, dateTo } = useSelector(
    (state: AppRootStateType) => state.countryReports);
  
  const handleChangeStartTimeDate = (newValue: number | null) => {
    if (newValue === null) return;
    dispatch(setCountryDateFromAC(newValue));
  };
  
  const handleChangeEndTimeDate = (newValue: number | null) => {
    if (newValue === null) return;
    dispatch(setCountryReportDateToAC(newValue));
  };

  
  return <FilterDatePicker dateFrom={dateFrom} dateTo={dateTo}
    handleChangeStartTimeDate={handleChangeStartTimeDate}
    handleChangeEndTimeDate={handleChangeEndTimeDate}/>;
};

export default CountryFilterDatePicker;