import { headerTableType, sortKeyEnum } from '../../../types/tableTemplateTypes';
import TableTemplate from '../../templates/TableTemplate/TableTemplate';
import Pagination from '../../modules/Pagination/Pagination';
import CountryReportFilter from '../../modules/CountryReport/CountryReportFilter';
import CountryReportSubHeader from '../../modules/CountryReport/CountryReportSubHeader';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootStateType } from '../../../store/store';
import { TableHeaderCeilTemplate } from 
  '../../templates/TableTemplate/TableHeaderCeilTemplate/TableHeaderCeilTemplate';
import { useEffect, useState } from 'react';
import { useCurrentInstanceData } from '../../../store/selectors/useCurrentInstanceData';
import { useCurrentIp } from '../../../store/selectors/useCurrentIp';
import { weworkAPI } from '../../../api/api';
import {
  setCountryReportIsLoadingAC,
  setCountryReportPaginationAC,
  setReportsAC
} from '../../../store/countryReportsReducer';
import CountryReportItem from '../../modules/CountryReport/CountryReportItem';
import { paginationType } from '../../../types/types';
import { utils_instance } from '../../../utils/Utils';
import { addNotificationAC } from '../../../store/appReducer';
import { notificationEnum } from '../../../types/appTypes';

const tableHeaderTemplateColumns = '18% 18% 18% 18% 18% 10%';

const tableHeaderItems: headerTableType[] = [
  {
    id: 1,
    name: 'Name',
    value: sortKeyEnum.FullName,
    isSortable: true,
  },
  {
    id: 2,
    name: 'UUID',
    value: sortKeyEnum.StartDate,
    isSortable: true,
  },
  {
    id: 3,
    name: 'Kind',
    value: sortKeyEnum.EndDate,
    isSortable: true,
  },
  {
    id: 4,
    name: 'Created at',
    value: sortKeyEnum.HolidayGroups,
    isSortable: true,
  },
  {
    id: 5,
    name: 'City',
    value: sortKeyEnum.HolidayGroups,
    isSortable: true,
  },
];

const CountryReportPage = () => {
  const { pagination, country, reports, uuid, isLoading,
    dateTo,
    dateFrom } = useSelector((state: AppRootStateType) =>
    state.countryReports);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const currentInstanceData = useCurrentInstanceData();
  const currentIp = useCurrentIp();

  const fetchReports = async () => {
    if (!currentInstanceData || !country) return;
    try {
      dispatch(setCountryReportIsLoadingAC(true));
      const request = utils_instance.countryReportRequest(
        country,
        pagination,
        dateFrom,
        dateTo,
        uuid
      );

      const data = await weworkAPI.getReports(
        currentIp,
        currentInstanceData.instance_id,
        currentInstanceData.server_id,
        currentInstanceData.platform,
        request
      );
      setTotalCount(data.total_count);
      dispatch(setReportsAC(data.reports));
    } catch {
      dispatch(addNotificationAC(notificationEnum.error, 'Error reports not found'));
      dispatch(setReportsAC([]));
      setTotalCount(0);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [country, pagination, uuid, dateTo, dateFrom]);

  const tableHeader = tableHeaderItems.map((item) => (
    <TableHeaderCeilTemplate key={item.id} item={item}  />
  ));

  const itemRow = (reports || []).map((item, index) => {
    return (
      <CountryReportItem
        key={`instance_logs_item_${index}`}
        item={item}
        tableHeaderTemplateColumns={tableHeaderTemplateColumns}
      />
    );
  });

  const handleChangePagination = (pagination: paginationType) => {
    dispatch(setCountryReportPaginationAC(pagination));
  };


  return     <>
    <TableTemplate
      header={<CountryReportFilter />}
      subHeader={<CountryReportSubHeader/>}
      tableHeader={tableHeader}
      tableHeaderTemplateColumns={tableHeaderTemplateColumns}
      isLoaded={isLoading}
      body={itemRow}
      alwaysShowFooter={true}
      footer={
        <Pagination pagination={pagination} setPagination={handleChangePagination}
          totalCount={totalCount}/>
      }
    >
    </TableTemplate>
  </>;
};

export default CountryReportPage;