import React from 'react';
import { DateTimePicker, TimePicker, DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

type regularPropsType = {
  isRegularMode: boolean;
  value: number | null;
  setValue: (value: number | null) => void;
  minValue: number | null;
  maxValue?: number | null;
  size?: 'small' | 'medium';
  dataTimeLabel: string;
  disablePast?: boolean;
  isDatePicker?: boolean;
  withSeconds? : boolean;
};
export const TimePickerByRegularMode: React.FC<regularPropsType> = (props) => {
  const {
    isRegularMode,
    value,
    setValue,
    minValue,
    maxValue = null,
    dataTimeLabel,
    disablePast = true ,
    isDatePicker,
    size = 'medium',
    withSeconds = false
  } = props;

  if (isRegularMode) {
    return (
      <TimePicker
        renderInput={(props) => <TextField {...props} fullWidth sx={{ marginTop: '1em' }} />}
        label={dataTimeLabel}
        value={value}
        onChange={(newValue) => setValue(newValue)}
      />
    );
  }

  if(isDatePicker){
    return (
      <DatePicker
        onChange={(newValue) => setValue(newValue)}
        label={dataTimeLabel}
        value={value}
        renderInput={(props) => <TextField size={size}
          {...props} fullWidth sx={{ marginTop: '1em' }} />}

        disablePast={disablePast}
        maxDate={maxValue}
        minDate={minValue}
      />
    );
  }

  if(withSeconds) {
    return (
      <DateTimePicker
        renderInput={(props) => <TextField size={size} InputLabelProps={{ shrink: true }}
          {...props} fullWidth />}
        label={dataTimeLabel}
        value={value}
        onChange={(newValue) => setValue(newValue)}
        disablePast={disablePast}
        minDateTime={minValue}
        views={['year', 'day', 'hours', 'minutes', 'seconds']}
        inputFormat="dd/MM/yyyy HH:mm:ss"
      />
    );
  }

  return (
    <DateTimePicker
      renderInput={(props) => <TextField size={size}
        {...props} fullWidth sx={{ marginTop: '1em' }} />}
      label={dataTimeLabel}
      value={value}
      onChange={(newValue) => setValue(newValue)}
      disablePast={disablePast}
      minDateTime={minValue}
    />
  );
};
