import { InstanceLogData, InstanceLogsFilterType } from '../types/instanceLogTypes';
import { paginationType } from '../types/types';

enum instanceLogsActionTypes {
    setDateForm = 'instanceLogs/setDateFrom',
    setDateTo = 'instanceLogs/setDateTo',
    setFilterType = 'instanceLogs/setFilterType',
    setPageSize = 'instanceLogs/setPageSize',
    setInitDate = 'instanceLogs/setInitDate',
    setLogs = 'instanceLogs/setLogs',
    addNewLog = 'instanceLogs/addNewLog',
    setLoaded = 'instanceLogs/setLoaded',
    setPagination = 'instanceLogs/setPagination',
    setPersonUUID = 'instanceLogs/setPersonUUID',
    setReaderName = 'instanceLogs/setReaderName',
    setMessage = 'instanceLogs/setMessage',
}

export type setDataType = ReturnType<typeof setDateFromAC>;
export type setDateTo = ReturnType<typeof setDateToAC>;
export type setFilterType = ReturnType<typeof setFilterTypeAC>;
export type setPageSize = ReturnType<typeof setPageSizeAC>;
export type setInitDate = ReturnType<typeof setInitDateAC>;
export type setLogs = ReturnType<typeof setInstanceLogsAC>;
export type addNewLog = ReturnType<typeof addNewLogAC>;
export type setLoaded = ReturnType<typeof setLogsIsLoading>;
export type setPagination = ReturnType<typeof setPaginationAC>;
export type setPersonUUID = ReturnType<typeof setPersonUUIDAC>;
export type setReaderName = ReturnType<typeof setReaderNameAC>;
export type setMessage = ReturnType<typeof setMessageAC>;

export type actionType = setDataType
| setDateTo
| setFilterType
| setPageSize
| setInitDate
| setLogs
| addNewLog
| setLoaded
| setPagination
| setPersonUUID
| setReaderName
| setMessage;


type InitStateType = {
  logs: InstanceLogData[],
  selectedFilterType: InstanceLogsFilterType
  pageSize: number,
  isLoading: boolean,
  pagination: paginationType,
  dateFrom: number,
  dateTo: number,
  personUUID?: string;
  readerName?: string;
  message?: string;
}

const initPagination = {
  page: 1,
  limit: 10,
};

export const initState: InitStateType = {
  selectedFilterType: 'Latest',
  logs: [] as InstanceLogData[],
  isLoading: false,
  pageSize: 10,
  pagination: initPagination,
  dateFrom: 0,
  dateTo: 0,
  personUUID: undefined,
  readerName: undefined,
  message: undefined,
};

export const setLogsIsLoading = (isLoading: boolean) => {
  return {
    type: 'instanceLogs/setLoaded',
    isLoaded: isLoading,
  } as const;
};

export const setInstanceLogsAC = (logs: InstanceLogData[]) => {
  return {
    type: 'instanceLogs/setLogs',
    logs,
  } as const;
};

export const addNewLogAC = (log: InstanceLogData) => {
  return {
    type: 'instanceLogs/addNewLog',
    log,
  } as const;
};

export const setInitDateAC = (dateForm: number, dateTo: number) => {
  return {
    type: 'instanceLogs/setInitDate',
    dateForm,
    dateTo,
  } as const;
};

export const setDateFromAC = (dateFrom: number) => {
  return {
    type: 'instanceLogs/setDateFrom',
    dateFrom,
  } as const;
};

export const setDateToAC = (dateTo: number) => {
  return {
    type: 'instanceLogs/setDateTo',
    dateTo,
  } as const;
};

export const setFilterTypeAC = (filterType: InstanceLogsFilterType) => {
  return {
    type: 'instanceLogs/setFilterType',
    filterType,
  } as const;
};


export const setPageSizeAC = (pageSize: number) => {
  return {
    type: 'instanceLogs/setPageSize',
    pageSize,
  } as const;
};

export const setPaginationAC = (pagination: paginationType) => {
  return {
    type: 'instanceLogs/setPagination',
    pagination,
  } as const;
};

export const setPersonUUIDAC = (personUUID: string) => {
  return {
    type: 'instanceLogs/setPersonUUID',
    personUUID,
  } as const;
};

export const setReaderNameAC = (readerName: string) => {
  return {
    type: 'instanceLogs/setReaderName',
    readerName,
  } as const;
};

export const setMessageAC = (message: string) => {
  return {
    type: 'instanceLogs/setMessage',
    message,
  } as const;
};

export const instanceLogsReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case instanceLogsActionTypes.setDateForm:
    return {
      ...state,
      dateFrom: action.dateFrom,
    };
  case instanceLogsActionTypes.setDateTo:
    return {
      ...state,
      dateTo: action.dateTo,
    };
  case instanceLogsActionTypes.setFilterType:
    return {
      ...state,
      selectedFilterType: action.filterType,
      pagination: initPagination,
    };
  case instanceLogsActionTypes.setPageSize:
    return {
      ...state,
      pageSize: action.pageSize,
    };
  case instanceLogsActionTypes.setInitDate:
    return {
      ...state,
      dateFrom: action.dateForm,
      dateTo: action.dateTo,
    };
  case instanceLogsActionTypes.setLogs:

    return {
      ...state,
      logs: action.logs,
      isLoading: false,
    };
  case instanceLogsActionTypes.addNewLog:
    const logs = [...state.logs, action.log];

    return {
      ...state,
      logs: logs.sort((a, b) => Date.parse(b.publish_on) - Date.parse(a.publish_on)),
    };
  case instanceLogsActionTypes.setLoaded:
    return {
      ...state,
      isLoading: action.isLoaded,
    };
  case instanceLogsActionTypes.setPagination:
    return {
      ...state,
      pagination: action.pagination,
    };
  case instanceLogsActionTypes.setPersonUUID:
    return {
      ...state,
      personUUID: action.personUUID,
    };
  case instanceLogsActionTypes.setReaderName:
    return {
      ...state,
      readerName: action.readerName,
    };
  case instanceLogsActionTypes.setMessage:
    return {
      ...state,
      message: action.message,
    };
  default:
    return state;
  }
};
