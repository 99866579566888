import React, { useEffect, useState } from 'react';
import s from './Pagination.module.css';
import { Button } from '@wework/ray2';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import { paginationType } from '../../../types/types';
import { utils_instance } from '../../../utils/Utils';
import ArrowPagination from './ArrowPagination';

type propsType = {
  pagination: paginationType;
  setPagination: (pagination: paginationType) => void;
  totalCount: number;
  arrowsMode?: boolean;
};

const Pagination: React.FC<propsType> = (props) => {
  const { pagination, setPagination, totalCount, arrowsMode = false } = props;

  const [numberButton, setNumberButton] = useState<Array<number>>([]);

  const buttonsArray = !arrowsMode && numberButton.map((item, idx) => {
    if (item > 0) {
      return (
        <Button
          size="medium"
          theme={pagination.page === item ? 'fill' : 'outline'}
          key={idx}
          onClick={() => changePage(item)}
        >
          {item}
        </Button>
      );
    } else {
      return (
        <span key={idx} className={s.spacePoint}>
          . . .
        </span>
      );
    }
  });

  const changePage = (page: number) => {
    setPagination({ ...pagination, page });
  };

  const changeLimit = (event: any) => {
    setPagination({ page: 1, limit: event.target.value });
  };

  useEffect(() => {
    if(arrowsMode) return;
    setNumberButton(utils_instance.getPaginationList(pagination, totalCount));
  }, [pagination, totalCount]);

  if (totalCount < pagination.limit) return null;

  return (
    <div className={s.content}>
      {arrowsMode ? <ArrowPagination
        pagination={pagination}
        changePage={changePage}/>
        :
        buttonsArray
      }
      <div>
        <FormControl>
          <InputLabel>Limit</InputLabel>
          <Select
            size="small"
            value={pagination.limit}
            label="Limit"
            onChange={(event) => changeLimit(event)}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={75}>75</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default Pagination;
