import { useDispatch, useSelector } from 'react-redux';
import { AppRootStateType } from '../../../store/store';
import { useCurrentInstanceData } from '../../../store/selectors/useCurrentInstanceData';
import { useCurrentIp } from '../../../store/selectors/useCurrentIp';
import { setCountryReportIsLoadingAC } from '../../../store/countryReportsReducer';
import { utils_instance } from '../../../utils/Utils';
import { weworkAPI } from '../../../api/api';
import { Button } from '@wework/ray2';

export const CountryReportExport = () => {
  const { pagination, country, uuid, isLoading,
    dateTo,
    dateFrom } = useSelector((state: AppRootStateType) =>
    state.countryReports);

  const dispatch = useDispatch();
  const currentInstanceData = useCurrentInstanceData();
  const currentIp = useCurrentIp();

  const handleExport = async () => {
    if (!currentInstanceData) return;
    dispatch(setCountryReportIsLoadingAC(true));

    const request = utils_instance.countryReportRequest(
      country,
      pagination,
      dateFrom,
      dateTo,
      uuid
    );


    const data = await weworkAPI.getCSVReports(
      currentIp,
      currentInstanceData.instance_id,
      currentInstanceData.server_id,
      currentInstanceData.platform,
      request,
    );

    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
    dispatch(setCountryReportIsLoadingAC(false));
  };

  return (
    <Button loading={isLoading} size="medium" theme="outline" onClick={handleExport}>
      Export in .csv
    </Button>
  );
};