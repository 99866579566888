import InstanceFilterDatePicker from './InstanceFilterDatePicker';
import InstanceFilterTable from './InstanceFilterTable';

type InstanceSubHeaderPropsType = {
  selectedFilterType: string
}

const InstanceSubHeader = ({ selectedFilterType }: InstanceSubHeaderPropsType) => {

  return <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
    {selectedFilterType === 'Date' && <InstanceFilterDatePicker/>}
    <InstanceFilterTable/>
  </div>;
};

export default InstanceSubHeader;