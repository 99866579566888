import React, { useState } from 'react';
import TableRowTemplate from '../../templates/TableTemplate/TableRowTemplate/TableRowTemplate';
import TableItemCeilTemplate from 
  '../../templates/TableTemplate/TableItemCeilTemplate/TableItemCeilTemplate';
import TableItemButtonTemplate
  from '../../templates/TableTemplate/TableItemButtonTemplate/TableItemButtonTemplate';
import { Button } from '@wework/ray2';
import cs from '../../../styles/commonStyles.module.css';
import MoreInfo from '../UserLogs/MoreInfo/MoreInfo';
import CustomModal from '../../templates/CustomModal/CustomModal';
import { CountryReport } from '../../../types/CountryReportsTypes';
import { utils_instance } from '../../../utils/Utils';

interface InstanceLogsItemProps {
    tableHeaderTemplateColumns: string;
    item: CountryReport;
}

const DATE_KEY = ['createdAt'];

const CountryReportItem = ({ tableHeaderTemplateColumns, item }: InstanceLogsItemProps) => {
  const [showModal, setShowModal] = useState(false);

  const itemKeys = Object.keys(item);
  const moreRow = itemKeys.map((key) => {
    let value = item[key as keyof CountryReport];

    if (DATE_KEY.includes(key)) {
      value = utils_instance.formatDateTime(value);
    }

    if (value) {
      return <MoreInfo
        itemKey={key}
        value={value}
        key={key}
        splitSymbol={'_'}
        isDetailedInformation={ key==='details'}/>;
    }
  });

  return (
    <>
      <TableRowTemplate tableHeaderTemplateColumns={tableHeaderTemplateColumns}>
        <TableItemCeilTemplate>
          {item.name}
        </TableItemCeilTemplate>
        <TableItemCeilTemplate>
          {item.uuid}
        </TableItemCeilTemplate>
        <TableItemCeilTemplate>
          {item.kind}
        </TableItemCeilTemplate>
        <TableItemCeilTemplate>
          {utils_instance.formatDateTime(item.createdAt)}
        </TableItemCeilTemplate>
        <TableItemCeilTemplate>
          {item.city}
        </TableItemCeilTemplate>
        <TableItemButtonTemplate>
          <Button
            size="small"
            theme="outline"
            onClick={() => setShowModal(true)}
            className={cs.buttonSize}
          >
            More info
          </Button>
        </TableItemButtonTemplate>
        {showModal && (
          <CustomModal
            titleText="More info"
            handleClose={setShowModal}
            width={800} isLineHovered={true}>
            {moreRow}
          </CustomModal>
        )}
      </TableRowTemplate>
    </>
  );
};

export default CountryReportItem;