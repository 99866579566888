import { useDispatch, useSelector } from 'react-redux';
import { AppRootStateType } from '../../../store/store'; 
import { weworkAPI } from '../../../api/api';
import { useCurrentInstanceData } from '../../../store/selectors/useCurrentInstanceData';
import { useCurrentIp } from '../../../store/selectors/useCurrentIp';
import { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { setCountryAC, setCountryReportIsLoadingAC } from '../../../store/countryReportsReducer';
import s from './CountryReport.module.css';

const CountryReportFilter = () => {
  const { country, isLoading } = useSelector(
    (state: AppRootStateType) =>  state.countryReports);
  const [countries, setCountries] = useState<string[]>([]);
  const dispatch = useDispatch();
  const currentInstanceData = useCurrentInstanceData();
  const currentIp = useCurrentIp();

  const fetchCountries = async () => {
    if (!currentInstanceData) return;
    dispatch(setCountryReportIsLoadingAC(true));
    const data = await weworkAPI.getCountries(
      currentIp,
      currentInstanceData.instance_id,
      currentInstanceData.server_id,
      currentInstanceData.platform,
    );
    dispatch(setCountryReportIsLoadingAC(false));
    setCountries(data);

  };

  useEffect(() => {
    fetchCountries();
  }, []);
  

  const changeCountry = (e: SelectChangeEvent<string>) => {
    const selectedCountry = e.target.value as string;
    dispatch(setCountryAC(selectedCountry));
  };

  return <div className={s.container}>
    <FormControl fullWidth size={'small'}
      className={s.containerControl}
      disabled={isLoading}
      key={'report_selector'}>
      <InputLabel id={'report_selector_type'}>Country</InputLabel>
      <Select
        size="small"
        labelId={'report_selector_type'}
        label='Country'
        id={'report_selector_id'}
        fullWidth
        value={country}
        onChange={(e) => changeCountry(e)}
        IconComponent={() => null}
      >
        {countries.map((country) => {
          return (
            <MenuItem key={`countries_${country}`} value={country}>
              {country}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>

  </div>;
}; 

export default CountryReportFilter;