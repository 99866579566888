import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
import { setCountryReportUUIDAC } from '../../../store/countryReportsReducer';
import s from './CountryReport.module.css';

const CountryReportFilterTable = () => {
  const dispatch = useDispatch();
  const [uuid, setUUID] = useState<string>('');

  const debouncedPerson = useDebouncedValue(uuid, 500);

  useEffect(() => {
    dispatch(setCountryReportUUIDAC(debouncedPerson));
  }, [debouncedPerson, dispatch]);

  return <FormControl className={s.containerSm}>
    <div className={s.wrapper}>
      <TextField
        fullWidth
        value={uuid}
        type={'text'}
        size={'small'}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setUUID(e.target.value)}
        label="Enter person UUID"
      />
    </div>
  </FormControl>;
}; 

export default CountryReportFilterTable;