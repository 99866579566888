import CountryFilterDatePicker from './CountryFilterDatePicker';
import CountryReportFilterTable from './CountryReportFilterTable';
import s from './CountryReport.module.css';
import { CountryReportExport } from './CountryReportExport';

const CountryReportSubHeader = () => {

  return <div className={s.subContainer}>
    <CountryFilterDatePicker/>
    <CountryReportFilterTable/>
    <CountryReportExport/>
  </div>;
};

export default CountryReportSubHeader;