import s from '../../pages/HolidayPage/HolidayPage.module.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePickerByRegularMode } from '../../../utils/TimePickerByRegularMode';

type Props = {
  dateFrom: number | null;
  dateTo: number | null;
  handleChangeStartTimeDate: (newValue: number | null) => void;
  handleChangeEndTimeDate: (newValue: number | null) => void;
  
}

const FilterDatePicker = ({ dateFrom, 
  dateTo, 
  handleChangeStartTimeDate, 
  handleChangeEndTimeDate }: Props) => {
  return <div className={s.date_wrapper}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePickerByRegularMode
        isRegularMode={false}
        isDatePicker={false}
        value={dateFrom}
        setValue={handleChangeStartTimeDate}
        minValue={null}
        disablePast={false}
        size='small'
        dataTimeLabel="Start time"
        withSeconds={true}
      />
    </LocalizationProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePickerByRegularMode
        isRegularMode={false}
        isDatePicker={false}
        disablePast={false}
        value={dateTo}
        setValue={handleChangeEndTimeDate}
        minValue={dateFrom}
        size='small'
        dataTimeLabel="End time"
        withSeconds={true}
      />
    </LocalizationProvider>
  </div>;
};

export default FilterDatePicker;