import { paginationType } from '../types/types';
import { CountryReport } from '../types/CountryReportsTypes';

enum countryReportsActionTypes {
    setCountryDateFrom = 'countryReports/setCountryDateFrom',
    setCountryReportDateTo = 'countryReports/setCountryReportDateTo',
    setCountryReportInitDate = 'countryReports/setCountryReportInitDate',
    setCountryReportPagination = 'countryReports/setCountryReportPagination',
    setCountryReportUUID = 'countryReports/setCountryReportUUID',
    setCountry = 'countryReports/setCountry',
    setReports = 'countryReports/setReports',
    setCountryReportIsLoading = 'countryReports/setCountryReportIsLoading',
}

export type setCountryReportDateTo = ReturnType<typeof setCountryReportDateToAC>;
export type setCountryDateFrom = ReturnType<typeof setCountryDateFromAC>;
export type setCountryReportInitDate = ReturnType<typeof setCountryReportInitDateAC>;
export type setCountryReportPagination = ReturnType<typeof setCountryReportPaginationAC>;
export type setCountryReportUUID = ReturnType<typeof setCountryReportUUIDAC>;
export type setCountry = ReturnType<typeof setCountryAC>;
export type setReports = ReturnType<typeof setReportsAC>;
export type setIsLoading = ReturnType<typeof setCountryReportIsLoadingAC>;

export type actionType = setCountryReportDateTo
| setCountryReportInitDate
| setCountryReportPagination
| setCountryReportUUID
| setCountry
| setCountryDateFrom
| setReports
| setIsLoading;


type InitStateType = {
  country: string;
  isLoading: boolean,
  pagination: paginationType,
  dateFrom: number | null,
  dateTo: number  | null,
  uuid?: string;
  reports: CountryReport[];
}

const initPagination = {
  page: 1,
  limit: 10,
};

export const initState: InitStateType = {
  country: '',
  pagination: initPagination,
  dateFrom: null,
  dateTo: null,
  uuid: undefined,
  reports: [],
  isLoading: false,
};

export const setReportsAC = (reports: CountryReport[]) => {
  return {
    type: 'countryReports/setReports',
    reports,
  } as const;
};

export const setCountryAC = (country: string) => {
  return {
    type: 'countryReports/setCountry',
    country,
  } as const;
};

export const setCountryReportInitDateAC = (dateForm: number, dateTo: number) => {
  return {
    type: 'countryReports/setCountryReportInitDate',
    dateForm,
    dateTo,
  } as const;
};

export const setCountryDateFromAC = (dateFrom: number) => {
  return {
    type: 'countryReports/setCountryDateFrom',
    dateFrom,
  } as const;
};

export const setCountryReportDateToAC = (dateTo: number) => {
  return {
    type: 'countryReports/setCountryReportDateTo',
    dateTo,
  } as const;
};

export const setCountryReportPaginationAC = (pagination: paginationType) => {
  return {
    type: 'countryReports/setCountryReportPagination',
    pagination,
  } as const;
};

export const setCountryReportUUIDAC = (personUUID: string) => {
  return {
    type: 'countryReports/setCountryReportUUID',
    personUUID,
  } as const;
};

export const setCountryReportIsLoadingAC = (isLoading: boolean) => {
  return {
    type: 'countryReports/setCountryReportIsLoading',
    isLoading,
  } as const;
};

export const countryReportsReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case countryReportsActionTypes.setCountryReportDateTo:
    return {
      ...state,
      dateTo: action.dateTo,
      pagination: { ...state.pagination, page: 1 }
    };
  case countryReportsActionTypes.setCountryDateFrom:
    return {
      ...state,
      dateFrom: action.dateFrom,
      pagination: { ...state.pagination, page: 1 }
    };
  case countryReportsActionTypes.setCountryReportInitDate:
    return {
      ...state,
      dateFrom: action.dateForm,
      dateTo: action.dateTo,
    };
  case countryReportsActionTypes.setCountryReportPagination:
    return {
      ...state,
      pagination: action.pagination,
    };
  case countryReportsActionTypes.setCountryReportUUID:
    return {
      ...state,
      uuid: action.personUUID,
      pagination: { ...state.pagination, page: 1 }
    };
  case countryReportsActionTypes.setCountry:
    return {
      ...state,
      country: action.country,
    };
  case countryReportsActionTypes.setReports:
    return {
      ...state,
      isLoading: false,
      reports: action.reports,
    };
  case countryReportsActionTypes.setCountryReportIsLoading:
    return {
      ...state,
      isLoading: action.isLoading,
    };
  default:
    return state;
  }
};
