import { applyMiddleware, combineReducers, createStore } from 'redux';
import { appReducer } from './appReducer';
import thunkMiddleware from 'redux-thunk';
import { readersReducer } from './readersReducer';
import { schedulesReducer } from './scheduleReducer';
import { camerasReducer } from './camerasReducer';
import { hardwareUsersReducer } from './hardwareUsersReducer';
import { downstreamReducer } from './downstreamReducer';
import { controllersReducer } from './controllersReducer';
import { userLogsReducer } from './userLogsReducer';
import { authReducer } from './authReducer';
import { instancesListReducer } from './instancesListReducer';
import { intercomsReducer } from './intercomsReducer';
import { elevatorsReducer } from './elevatorsReducer';
import { sysLogsReducer } from './sysLogsReducer';
import { instanceGroupsReducer } from './instanceGroupsReducer';
import { countingAreasReducer } from './countingAreasReducer';
import { holidaysReducer } from './holidaysReducer';
import { holidaysGroupReducer } from './holidaysGroupReducer';
import { instanceLogsReducer } from './instanceLogsReducer';
import { countryReportsReducer } from './countryReportsReducer';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  readers: readersReducer,
  downstream: downstreamReducer,
  controllers: controllersReducer,
  counting_areas: countingAreasReducer,
  schedules: schedulesReducer,
  cameras: camerasReducer,
  intercoms: intercomsReducer,
  hardwareUsers: hardwareUsersReducer,
  instancesList: instancesListReducer,
  elevators: elevatorsReducer,
  userLogs: userLogsReducer,
  sysLogs: sysLogsReducer,
  instanceGroups: instanceGroupsReducer,
  holidays: holidaysReducer,
  holidaysGroups: holidaysGroupReducer,
  instanceLogs: instanceLogsReducer,
  countryReports: countryReportsReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export type AppRootStateType = ReturnType<typeof rootReducer>;

// @ts-expect-error needed for debug
window.store = store;
